import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// import * as state from "./states";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";
import { consts } from "./Modules/consts";
import user from "./Modules/Account/user";
import subscriberProfile from "./Modules/Profile/subscriberProfile";
import content from "./Modules/Content/content";
import purchase from "./Modules/purchase/purchase";
import firebaseFunctions from "./Modules/Firebase/firebaseFunctions";

console.log("process.env===========", process.env)
let cookies_permission = localStorage.getItem('cookies_permission');
if (cookies_permission == 'true') {
  cookies_permission = true;
} else if (cookies_permission == 'false') {
  cookies_permission = false;
} else {
  cookies_permission = null;
}

export const store = new Vuex.Store({
  state: {
    name: "Vlive Web",
    oldbaseUrl: "https://vliveui.mobiotics.com/",
    vChargeBaseUrl: null,
    vCmsBaseUrl: null,
    vCrmBaseUrl: null,
    vDrmBaseUrl: null,
    vSmsBaseUrl: null,
    appConfig: null,
    country: null,
    token: null,
    subscriberid: null,
    profileid: null,
    kidsMode:false,
    genreData: undefined,
    secretKey: "vlive",
    availabilityList: [],
    subscriptionList: "",
    purchaseList: [],
    newSubscription: false,
    newPurchase: false,
    currentBrowser: false,
    displayLanguage: [],
    displayLanguageCode: null,
    rtl: false,
    isMovieEnabled: "",
    regActiveDeeplink: false,
    unauthorizedErrorOccured: false,
    firebaseUserUID: null,
    ContinueWatchData:null,
    gatewayList: null,
    isManageCards: false,

    audioAndSubtitleLangState: null,

    allLanguageFullForm: null,

    episodePaginationInProgress: false,

    subscriberProfileDetails: null,

    planDeeplinkPath: null,
    countryListByCode: null,
		countryCodesList: null,
		pairingFlag: null,
    searchList: [],
    subscriptionInformation: '',
    cookiePermission: cookies_permission
  },
  getters,
  mutations,
  actions,
  modules: {
    user,
    subscriberProfile,
    content,
    purchase,
    consts,
    firebaseFunctions,
  },
});
